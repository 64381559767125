import { ArrowLeftOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, message, Row } from 'antd';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout';
import { createAccount } from '../services/auth';
import { handleErrorResponse } from '../utilities/errors';

const Register: FC = () => {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const registerAccount = async (values: any) => {
    setIsSubmitting(true);
    try {
      await createAccount(values);
      message.success('Account created successfully. You can now login.');
      navigate('/');
    } catch (error) {
      handleErrorResponse(error);
    }
    setIsSubmitting(false);
  };

  return (
    <AuthLayout>
      <Card title="Create an account" style={{ minHeight: '300px' }}>
        <Form layout="vertical" onFinish={registerAccount}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter your name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please enter a valid email address!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please enter a valid password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm password"
            name="password_confirmation"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The two passwords that you entered do not match!')
                  );
                }
              })
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Row justify="space-between">
              <Button
                loading={isSubmitting}
                icon={<ArrowLeftOutlined />}
                onClick={() => navigate('/')}
                htmlType="button"
              >
                Back
              </Button>
              <Button
                loading={isSubmitting}
                icon={<UserAddOutlined />}
                type="primary"
                htmlType="submit"
              >
                Create an account
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </AuthLayout>
  );
};

export default Register;
