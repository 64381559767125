import {
  ClockCircleOutlined,
  CommentOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import { Button, Col, Form, Input, message, Modal, Row, Space, Tabs, Tag } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { deleteComment } from '../../services/comments';
import { IProjectMember, listProjectMembers } from '../../services/projects';
import {
  createProjectTask,
  createTaskComment,
  createTaskTimeEntry,
  deleteTask,
  getTaskComments,
  getTaskStatuses,
  getTaskPriorities,
  getTaskTimeEntries,
  getTaskTypes,
  ITask,
  ITaskComment,
  ITaskStatus,
  ITaskTimeEntry,
  ITaskType,
  TaskStatusType,
  TaskTypeType,
  updateProjectTask,
  ITaskPriority,
  TaskPriorityType
} from '../../services/tasks';
import { deleteTimeEntry } from '../../services/time-entries';
import { UserContext } from '../../store';
import { handleErrorResponse } from '../../utilities/errors';
import CommentsList from '../Comments/CommentsList';
import TimeEntriesList, { ITaskTimeEntryInput } from '../TimeEntries/TimeEntriesList';
import TaskAssignees from './TaskAssignees';
import TaskPriorities from './TaskPriorities';
import TaskStatuses from './TaskStatuses';
import TaskTypes from './TaskTypes';

interface ITaskModalProps {
  open: boolean;
  setOpen: Function;
  projectId: number | undefined;
  task?: ITask;
  onTaskCreated?: Function;
  onTaskUpdated?: Function;
  onTaskDeleted?: Function;
  isProjectOwner?: boolean;
}

const TaskModal = ({
  open,
  setOpen,
  projectId,
  task,
  onTaskCreated,
  onTaskUpdated,
  onTaskDeleted,
  isProjectOwner = false
}: ITaskModalProps) => {
  const [form] = Form.useForm();
  const [isMembersLoading, setIsMembersLoading] = useState<boolean>(false);
  const [isStatusesLoading, setIsStatusesLoading] = useState<boolean>(false);
  const [isTypesLoading, setIsTypesLoading] = useState<boolean>(false);
  const [isPrioritiesLoading, setIsPrioritiesLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [members, setMembers] = useState<IProjectMember[]>([]);
  const [statuses, setStatuses] = useState<ITaskStatus[]>([]);
  const [priorities, setPriorities] = useState<ITaskPriority[]>([]);
  const [types, setTypes] = useState<ITaskType[]>([]);
  const [comments, setComments] = useState<ITaskComment[]>([]);
  const [timeEntries, setTimeEntries] = useState<ITaskTimeEntry[]>([]);
  const [taskHasBeenUpdated, setTaskHasBeenUpdated] = useState<boolean>(false);
  const [totalHours, setTotalHours] = useState<number>(0);
  const [isAddingTimeEntry, setIsAddingTimeEntry] = useState<boolean>(false);
  const [isAddingComment, setIsAddingComment] = useState<boolean>(false);
  const { user } = useContext(UserContext);

  const defaultTaskType: TaskTypeType = 'task';
  const defaultTaskStatus: TaskStatusType = 'backlog';
  const defaultTaskPriority: TaskPriorityType = 'medium';

  const fetchProjectMembers = async () => {
    if (!projectId) return;
    setIsMembersLoading(true);
    try {
      const { data } = await listProjectMembers(projectId);
      setMembers(data.map(({ id, name }: IProjectMember) => ({ label: name, value: id })));
    } catch (error) {
      handleErrorResponse(error);
    }
    setIsMembersLoading(false);
  };

  const fetchTaskTypes = async () => {
    setIsTypesLoading(true);
    try {
      const taskTypes = await getTaskTypes();
      setTypes(taskTypes);
    } catch (error) {
      handleErrorResponse(error);
    }
    setIsTypesLoading(false);
  };

  const fetchTaskStatuses = async () => {
    setIsStatusesLoading(true);
    try {
      const taskStatuses = await getTaskStatuses();
      setStatuses(taskStatuses);
    } catch (error) {
      handleErrorResponse(error);
    }
    setIsStatusesLoading(false);
  };

  const fetchTaskPriorities = async () => {
    setIsPrioritiesLoading(true);
    try {
      const taskPriorities = await getTaskPriorities();
      setPriorities(taskPriorities);
    } catch (error) {
      handleErrorResponse(error);
    }
    setIsPrioritiesLoading(false);
  };

  const fetchTaskComments = async () => {
    if (!task?.id) return;
    try {
      const { data } = await getTaskComments(Number(task?.id));
      setComments(data);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const fetchTaskTimeEntries = async () => {
    if (!task?.id) return;
    try {
      const { data } = await getTaskTimeEntries(Number(task?.id));
      const sumHours = data.reduce((previousValue: number, currentValue: ITaskTimeEntry) => {
        return previousValue + Number(currentValue.hours);
      }, 0);
      setTotalHours(sumHours);
      setTimeEntries(data);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const createSingleTimeEntry = async (input: ITaskTimeEntryInput) => {
    setIsAddingTimeEntry(true);
    try {
      await createTaskTimeEntry(Number(task?.id), input.hours, input.description);
      message.success('Time entry has been logged');
      setTaskHasBeenUpdated(true);
      await fetchTaskTimeEntries();
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setIsAddingTimeEntry(false);
    }
  };

  const deleteSingleTimeEntry = async (entry: ITaskTimeEntry) => {
    Modal.confirm({
      title: 'Delete time entry',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you wish to delete this time entry?',
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await deleteTimeEntry(entry.id);
          setTaskHasBeenUpdated(true);
          message.success('Time entry deleted successfully.');
          await fetchTaskTimeEntries();
        } catch (error) {
          handleErrorResponse(error);
        }
      }
    });
  };

  const createSingleComment = async (comment: string) => {
    setIsAddingComment(true);
    try {
      await createTaskComment(Number(task?.id), comment);
      message.success('You made a comment!');
      setTaskHasBeenUpdated(true);
      await fetchTaskComments();
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setIsAddingComment(false);
    }
  };

  const deleteSingleComment = async (comment: ITaskComment) => {
    Modal.confirm({
      title: 'Delete comment',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you wish to delete this comment?',
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          await deleteComment(comment.id);
          message.success('Comment deleted successfully.');
          setTaskHasBeenUpdated(true);
          await fetchTaskComments();
        } catch (error) {
          handleErrorResponse(error);
        }
      }
    });
  };

  useEffect(() => {
    fetchProjectMembers();
    fetchTaskTypes();
    fetchTaskStatuses();
    fetchTaskComments();
    fetchTaskTimeEntries();
    fetchTaskPriorities();
  }, []); // eslint-disable-line

  useEffect(() => {
    form.setFieldsValue({
      title: task?.title || '',
      description: task?.description || '',
      assignee: task?.assignee?.id || '',
      type: task?.type || defaultTaskType,
      status: task?.status || defaultTaskStatus,
      priority: task?.priority || defaultTaskPriority
    });
    fetchProjectMembers();
    fetchTaskComments();
    fetchTaskTimeEntries();
  }, [task, form, projectId]); // eslint-disable-line

  const onClose = () => {
    form.resetFields();
    if (taskHasBeenUpdated && onTaskUpdated) onTaskUpdated();
    setOpen(false);
  };

  const onSubmit = async (values: any) => {
    if (!projectId) return;
    setIsSubmitting(true);
    const { title, description, assignee, type, status, priority } = values;
    try {
      if (task) {
        await updateProjectTask(projectId, task.id, {
          title: title,
          description: description,
          assignee_id: assignee,
          type: type,
          status: status,
          priority: priority
        });
        message.success('Task updated successfully.');
        if (onTaskUpdated) onTaskUpdated();
      } else {
        await createProjectTask(projectId, {
          title: title,
          description: description,
          assignee_id: assignee,
          type: type,
          status: status,
          priority: priority
        });
        message.success('Task created successfully.');
        if (onTaskCreated) onTaskCreated();
      }
      setOpen(false);
      form.resetFields();
    } catch (error) {
      handleErrorResponse(error);
    }
    setIsSubmitting(false);
  };

  const onDeleteTask = async () => {
    Modal.confirm({
      title: 'Delete task',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you wish to delete this task?',
      okText: 'Yes',
      cancelText: 'Cancel',
      onOk: async () => {
        if (!task) return;
        setIsDeleting(true);
        try {
          await deleteTask(task.id);
          message.success('Task has been deleted successfully.');
          if (onTaskDeleted) {
            onTaskDeleted();
          }
          setOpen(false);
        } catch (error) {
          handleErrorResponse(error);
        } finally {
          setIsDeleting(false);
        }
      }
    });
  };

  return (
    <Modal
      forceRender
      onCancel={onClose}
      footer={null}
      title={task?.title || 'Create a new task'}
      visible={open}
      width={'800px'}
    >
      <Form
        initialValues={{
          title: task?.title || '',
          description: task?.description || '',
          assignee: task?.assignee?.id || '',
          type: task?.type || defaultTaskType,
          status: task?.status || defaultTaskStatus,
          priority: task?.priority || defaultTaskPriority
        }}
        form={form}
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row>
          <Col xs={24} lg={15}>
            <Form.Item
              name="title"
              label="Title"
              required
              rules={[{ required: true, message: 'Please enter a valid title!' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name="description" label="Description" rules={[{ required: false }]}>
              <Input.TextArea rows={5} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={{ span: 8, offset: 1 }}>
            <TaskAssignees isLoading={isMembersLoading} assignees={members} />
            <TaskTypes isLoading={isTypesLoading} types={types} />
            <TaskStatuses isLoading={isStatusesLoading} statuses={statuses} />
            <TaskPriorities isLoading={isPrioritiesLoading} priorities={priorities} />
          </Col>
        </Row>
        <Row justify="end">
          <Col>
            {task && (task.owner.id === user.id || isProjectOwner) && (
              <Button
                icon={<DeleteOutlined />}
                loading={isDeleting}
                type="default"
                htmlType="button"
                danger
                onClick={() => onDeleteTask()}
                style={{ marginRight: '0.5rem' }}
              />
            )}
            <Button
              icon={task ? <EditOutlined /> : <PlusOutlined />}
              loading={isSubmitting}
              type="primary"
              htmlType="submit"
            >
              {task ? 'Update task' : 'Create task'}
            </Button>
          </Col>
        </Row>
      </Form>
      {task && (
        <Row>
          <Col xs={24} lg={24}>
            <Tabs size="small" tabBarGutter={8} type="card" style={{ margin: '0' }}>
              <Tabs.TabPane
                style={{ paddingLeft: '0', paddingTop: '0' }}
                tab={
                  <Space direction="horizontal">
                    <CommentOutlined />
                    Comments
                    <Tag color="default">{comments.length}</Tag>
                  </Space>
                }
                key="1"
              >
                <CommentsList
                  isAddingComment={isAddingComment}
                  onAddComment={(value: string) => createSingleComment(value)}
                  onDeleteComment={(comment: ITaskComment) => deleteSingleComment(comment)}
                  comments={comments}
                />
              </Tabs.TabPane>
              <Tabs.TabPane
                style={{ paddingLeft: '0', paddingTop: '0' }}
                tab={
                  <Space direction="horizontal">
                    <ClockCircleOutlined />
                    Time Entries
                    <Tag color="default">{`${totalHours}h`}</Tag>
                  </Space>
                }
                key="2"
              >
                <TimeEntriesList
                  timeEntries={timeEntries}
                  onDeleteTimeEntry={(entry: ITaskTimeEntry) => deleteSingleTimeEntry(entry)}
                  onAddTimeEntry={(input: ITaskTimeEntryInput) => createSingleTimeEntry(input)}
                  isAddingTimeEntry={isAddingTimeEntry}
                />
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default TaskModal;
