import { TProjectStatus } from '../services/projects';
import { IProject } from '../services/projects';

export const getColorForStatus = (status: TProjectStatus) => {
  if (!status) {
    return 'green';
  }

  return {
    open: 'green',
    closed: 'red'
  }[status];
};

export const setColorForRole = (role: string) => {
  if (role === 'owner') {
    return 'green';
  }
  if (role === 'member') {
    return 'blue';
  }
  return 'red';
};

export const isProjectOwner = (project: IProject | null, userId: Number | undefined) => {
  if (project === null || userId === undefined) {
    return false;
  }
  for (let i = 0; i < project.members.length; i++) {
    if (project.members[i].role === 'owner' && project.members[i].id === userId) {
      return true;
    }
  }
  return false;
};
