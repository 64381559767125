import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal } from 'antd';
import { useState, useContext } from 'react';
import { IUpdateMeRequest, updateMe } from '../../services/users';
import { UserContext } from '../../store';
import { handleErrorResponse } from '../../utilities/errors';

interface IAccountSettingsModalProps {
  open: boolean;
  setOpen: Function;
}

const AccountSettingsModal = ({ open, setOpen }: IAccountSettingsModalProps) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setData, token, isLoggedIn } = useContext(UserContext);

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    try {
      const valuesToUpdate: IUpdateMeRequest = {
        name: values.name,
      }
      if (values.password !== '') {
        valuesToUpdate.password = values.password;
        valuesToUpdate.password_confirmation = values.password_confirmation;
      }
      const { data: updatedUser } = await updateMe(valuesToUpdate);
      setData({
        token,
        isLoggedIn,
        user: updatedUser,
      });
      message.success('Account details updated successfully.');
      setOpen(false);
    } catch (error) {
      handleErrorResponse(error);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal onCancel={onClose} title="Account Settings" footer={null} visible={open}>
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please enter your name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="New Password"
          name="password"
          rules={[{ required: false, message: 'Please enter a valid password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          label="Confirm new password"
          name="password_confirmation"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: false,
              message: 'Please confirm your password!'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              }
            })
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
          <Button
            icon={<EditOutlined />}
            type="primary"
            loading={isSubmitting}
            htmlType="submit"
          >
            Update
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AccountSettingsModal;
