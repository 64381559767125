import { Col, Row } from 'antd';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoSquare from '../assets/images/logo-square.png';

const AuthLayout: FC = ({ children }) => {
  const navigate = useNavigate();

  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
      <Col xs={22} lg={20} xl={8} xxl={8} style={{ maxWidth: '450px' }}>
        <div style={{ textAlign: 'center', marginBottom: '45px' }}>
          <img
            className="responsive"
            src={LogoSquare}
            style={{ borderRadius: '5px', cursor: 'pointer' }}
            width={150}
            alt="Tuesday Logo"
            onClick={() => navigate('/')}
          />
        </div>
        {children}
      </Col>
    </Row>
  );
};

export default AuthLayout;
