import { Avatar, Button, Comment, Empty, Form, Input, List } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { formatRelative } from 'date-fns';
import React, { useContext, useState } from 'react';
import { ITaskComment } from '../../services/tasks';
import { UserContext } from '../../store';
import { stringToColor } from '../../utilities/strings';

interface ICommentsListProps {
  comments: Array<ITaskComment>;
  isAddingComment: boolean;
  onAddComment?: Function;
  onDeleteComment?: Function;
}

interface ICommentsEditorProps {
  onChange: Function;
  onSubmit: Function;
  submitting: boolean;
  value: string;
}

const CommentsEditor = ({ onChange, onSubmit, submitting, value }: ICommentsEditorProps) => (
  <>
    <Form.Item style={{ marginBottom: '0.8rem' }}>
      <Input.TextArea
        rows={1}
        onChange={(e) => onChange(e)}
        value={value}
        placeholder="Type your comment..."
      />
    </Form.Item>
    <Form.Item style={{ marginBottom: '0' }}>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={() => onSubmit()}
        type="primary"
        icon={<PlusOutlined />}
      >
        Add Comment
      </Button>
    </Form.Item>
  </>
);

const CommentsList = ({
  comments,
  onAddComment,
  onDeleteComment,
  isAddingComment
}: ICommentsListProps) => {
  const [value, setValue] = useState<string>('');
  const { user } = useContext(UserContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value);

  const handleSubmit = () => {
    if (onAddComment) {
      onAddComment(value);
    }
    setValue('');
  };

  return (
    <>
      <Comment
        avatar={
          <Avatar style={{ backgroundColor: stringToColor(user.name) }}>
            {user.name.charAt(0)}
          </Avatar>
        }
        content={
          <CommentsEditor
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitting={isAddingComment}
            value={value}
          />
        }
      />
      {!comments || comments.length === 0 ? (
        <Empty description="No comments yet" />
      ) : (
        <List
          style={{ maxHeight: '300px', overflowY: 'scroll' }}
          dataSource={comments}
          size="small"
          itemLayout="horizontal"
          renderItem={(comment: ITaskComment) => (
            <Comment
              author={comment.owner.name}
              content={comment.body}
              avatar={
                <Avatar style={{ backgroundColor: stringToColor(comment.owner.name) }}>
                  {comment.owner.name.charAt(0)}
                </Avatar>
              }
              actions={[
                user.id === comment.owner.id && (
                  <span
                    key="delete"
                    onClick={() => {
                      if (onDeleteComment) onDeleteComment(comment);
                    }}
                  >
                    Delete
                  </span>
                )
              ]}
              datetime={<span>{formatRelative(new Date(comment.created_at), new Date())}</span>}
            />
          )}
        />
      )}
    </>
  );
};

export default CommentsList;
