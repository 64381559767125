import { Button, message, Typography } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { handleErrorResponse } from '../../utilities/errors';
import { closeProject } from '../../services/projects';
import { useNavigate } from 'react-router-dom';

interface IProjectProps {
  projectId: number;
}

const CloseProject = ({ projectId }: IProjectProps) => {
  const navigate = useNavigate();
  const { Title } = Typography;
  const closeProjectButton = async (projectId: number) => {
    try {
      await closeProject(projectId);
      message.success('Project closed successfully.');
      navigate(`/dashboard`);
    } catch (error) {
      handleErrorResponse(error);
    }
  };
  return (
    <>
      <Title level={5}>
        Closing this project is like archiving it. No more tasks or time entries can be
        created/updated for this project.
      </Title>
      <Button
        type="default"
        icon={<CloseCircleOutlined />}
        danger
        style={{ marginTop: '1rem' }}
        onClick={(e) => {
          e.preventDefault();
          closeProjectButton(projectId);
        }}
      >
        Close Project
      </Button>
    </>
  );
};

export default CloseProject;
