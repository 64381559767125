import apiClient from '../utilities/api-client';
import { IProject } from './projects';

export type TaskStatusType = 'backlog' | 'in_progress' | 'done' | 'blocked' | 'closed';
export type TaskTypeType = 'bug' | 'task' | 'feature';
export type TaskPriorityType = 'low' | 'medium' | 'high';

export interface IAssignee {
  id: number;
  name: string;
  email: string;
}

export interface ITask {
  id: number;
  title: string;
  description: string | null;
  type: TaskTypeType;
  hours: number;
  status: TaskStatusType;
  priority: TaskPriorityType;
  assignee: IAssignee;
  project: IProject;
  owner: IAssignee;
}
export interface ITaskType {
  label: string;
  value: TaskTypeType;
}

export interface ITaskStatus {
  label: string;
  value: TaskStatusType;
}

export interface ITaskPriority {
  label: string;
  value: TaskPriorityType;
}

export interface ITaskComment {
  id: number;
  body: string;
  owner: IAssignee;
  created_at: Date;
  updated_at: Date;
}

export interface ITaskTimeEntry {
  id: number;
  hours: string;
  description: string | null;
  owner: IAssignee;
  created_at: Date;
  updated_at: Date;
}

export interface CreateProjectTaskParams {
  title: string;
  description?: string;
  assignee_id?: number;
  type?: TaskTypeType;
  status?: TaskStatusType;
  priority?: TaskPriorityType;
}

export interface UpdateProjectTaskParams {
  title: string;
  description?: string;
  assignee_id?: number;
  type?: TaskTypeType;
  status?: TaskStatusType;
  priority?: TaskPriorityType;
}

export interface TaskFilters {
  status?: string;
}

export const listTasks = async () => apiClient.get('/tasks');

export const getProjectTasks = async (
  projectId: number,
  page: number = 1,
  filters: TaskFilters = {},
  per_page: number = 15
) => {
  const filterQueryParams = Object.keys(filters)
    .map(function (key) {
      return '&filters[' + key + ']=' + filters[key as keyof TaskFilters];
    })
    .join('&');

  return apiClient.get(
    `/projects/${projectId}/tasks?page=${page}&per_page=${per_page}${filterQueryParams}`
  );
};

export const getNonClosedProjectTasks = async (projectId: number) =>
  apiClient.get(`/projects/${projectId}/tasks/all`);

export const createProjectTask = async (projectId: number, task: CreateProjectTaskParams) =>
  apiClient.post(`/projects/${projectId}/tasks`, task);

export const getTask = async (taskId: number) => apiClient.get(`/tasks/${taskId}`);

export const deleteTask = async (taskId: number) => apiClient.remove(`/tasks/${taskId}`);

export const updateProjectTask = async (
  projectId: number,
  taskId: number,
  task: UpdateProjectTaskParams
) => apiClient.put(`/projects/${projectId}/tasks/${taskId}`, task);

export const getTaskTypes = async () => apiClient.get('/task_types');

export const getTaskStatuses = async () => apiClient.get('/task_statuses');

export const getTaskPriorities = async () => apiClient.get(`/task_priorities`);

export const getTaskTimeEntries = async (taskId: number) =>
  apiClient.get(`/tasks/${taskId}/time_entries`);

export const getTaskComments = async (taskId: number) => apiClient.get(`/tasks/${taskId}/comments`);

export const createTaskComment = async (taskId: number, comment: string) =>
  apiClient.post(`/tasks/${taskId}/comments`, { body: comment });

export const createTaskTimeEntry = async (taskId: number, hours: string, description?: string) =>
  apiClient.post(`/tasks/${taskId}/time_entries`, { hours, description });
