import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row } from 'antd';
import { useState } from 'react';
import { createProject } from '../../services/projects';
import { handleErrorResponse } from '../../utilities/errors';

interface IProjectModalProps {
  open: boolean;
  setOpen: Function;
}

const ProjectModal = ({ open, setOpen }: IProjectModalProps) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const onSubmit = async (values: any) => {
    setIsSubmitting(true);
    try {
      const { title, description, costPerHour, estimatedHours, timeline } = values;
      const startedAt = timeline.length > 1 ? timeline[0]?.format('YYYY-MM-DD') : null;
      const deliveredAt = timeline.length === 2 ? timeline[1]?.format('YYYY-MM-DD') : null;
      await createProject({
        title,
        description,
        cost_per_hour: costPerHour,
        estimated_hours: estimatedHours,
        started_at: startedAt,
        delivered_at: deliveredAt
      });
      message.success('Project created successfully.');
      form.resetFields();
      setOpen(false);
    } catch (error) {
      handleErrorResponse(error);
    }
    setIsSubmitting(false);
  };

  return (
    <Modal onCancel={onClose} title="New project" footer={null} visible={open}>
      <Form
        onFinishFailed={(e) => console.log(e)}
        initialValues={{
          costPerHour: 0,
          estimatedHours: 0,
          timeline: []
        }}
        form={form}
        onFinish={onSubmit}
        layout="vertical"
      >
        <Form.Item
          name="title"
          label="Title"
          required
          rules={[{ required: true, message: 'Please enter a valid title!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input.TextArea />
        </Form.Item>

        <Row gutter={24} justify="space-between">
          <Col span={12}>
            <Form.Item name="costPerHour" label="Cost per hour">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="estimatedHours" label="Estimated hours">
              <InputNumber style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="timeline" label="Project timeline">
          <DatePicker.RangePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
          <Button
            icon={<PlusCircleOutlined />}
            type="primary"
            loading={isSubmitting}
            htmlType="submit"
          >
            Create project
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ProjectModal;
