import { ClockCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Avatar, Button, Comment, Empty, Form, Input, InputNumber, List, Space, Tag } from 'antd';
import { formatRelative } from 'date-fns';
import { useContext, useState } from 'react';
import { ITaskTimeEntry } from '../../services/tasks';
import { UserContext } from '../../store';
import { stringToColor } from '../../utilities/strings';

interface ITimeEntriesProps {
  timeEntries?: ITaskTimeEntry[];
  onAddTimeEntry?: Function;
  onDeleteTimeEntry?: Function;
  isAddingTimeEntry: boolean;
}

interface ITimeEntriesEditorProps {
  onChange: Function;
  onSubmit: Function;
  submitting: boolean;
  entry: ITaskTimeEntryInput;
}

export interface ITaskTimeEntryInput {
  hours: string;
  description?: string;
}

const entryInitialValues: ITaskTimeEntryInput = {
  hours: '',
  description: ''
};

const TimeEntriesEditor = ({ onChange, onSubmit, submitting, entry }: ITimeEntriesEditorProps) => (
  <>
    <Form layout="inline">
      <Form.Item style={{ marginBottom: '0.8rem', width: '25%' }}>
        <InputNumber
          onChange={(value: string) => onChange({ hours: value, description: entry.description })}
          value={entry.hours}
          placeholder="Hours"
          addonBefore={<ClockCircleOutlined />}
          addonAfter={`hours`}
          min="1"
        />
      </Form.Item>
      <Form.Item style={{ marginBottom: '0.8rem', flexGrow: 1 }}>
        <Input.TextArea
          rows={2}
          onChange={(e) => onChange({ hours: entry.hours, description: e.target.value })}
          value={entry.description}
          placeholder="Type a description..."
        />
      </Form.Item>
    </Form>
    <Form.Item style={{ marginBottom: '0' }}>
      <Button
        htmlType="submit"
        loading={submitting}
        onClick={() => onSubmit()}
        type="primary"
        icon={<PlusOutlined />}
      >
        Add Time Entry
      </Button>
    </Form.Item>
  </>
);

const TimeEntriesList = ({
  timeEntries,
  onAddTimeEntry,
  onDeleteTimeEntry,
  isAddingTimeEntry
}: ITimeEntriesProps) => {
  const [entry, setEntry] = useState<ITaskTimeEntryInput>(entryInitialValues);
  const { user } = useContext(UserContext);

  const handleChange = (newEntry: ITaskTimeEntryInput) => setEntry(newEntry);

  const handleSubmit = () => {
    if (onAddTimeEntry) {
      onAddTimeEntry(entry);
    }
    setEntry(entryInitialValues);
  };

  return (
    <>
      <Comment
        avatar={
          <Avatar style={{ backgroundColor: stringToColor(user.name) }}>
            {user.name.charAt(0)}
          </Avatar>
        }
        content={
          <TimeEntriesEditor
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitting={isAddingTimeEntry}
            entry={entry}
          />
        }
      />

      {!timeEntries || timeEntries.length === 0 ? (
        <Empty description="No time entries yet" />
      ) : (
        <List
          dataSource={timeEntries}
          itemLayout="horizontal"
          style={{ maxHeight: '300px', overflowY: 'scroll', wordBreak: 'break-all' }}
          renderItem={(timeEntry: ITaskTimeEntry) => (
            <Comment
              author={timeEntry.owner.name}
              content={
                <Space direction="horizontal">
                  <Tag icon={<ClockCircleOutlined />} color="default">
                    {timeEntry.hours}h
                  </Tag>
                  {timeEntry.description}
                </Space>
              }
              avatar={
                <Avatar style={{ backgroundColor: stringToColor(timeEntry.owner.name) }}>
                  {timeEntry.owner.name.charAt(0)}
                </Avatar>
              }
              actions={[
                <span
                  key="delete"
                  onClick={() => {
                    if (onDeleteTimeEntry) onDeleteTimeEntry(timeEntry);
                  }}
                >
                  Delete
                </span>
              ]}
              datetime={<span>{formatRelative(new Date(timeEntry.created_at), new Date())}</span>}
            />
          )}
        />
      )}
    </>
  );
};

export default TimeEntriesList;
