import { TaskStatusType } from '../services/tasks';

export const getColourForType = (type: 'bug' | 'task' | 'feature') => {
  return {
    bug: 'red',
    task: 'blue',
    feature: 'green'
  }[type];
};

export const ribbonForType = (type: 'bug' | 'task' | 'feature') => {
  return {
    bug: 'Bug',
    task: 'Task',
    feature: 'Feature'
  }[type];
};

export const statusForColumn = (
  status: TaskStatusType
): 'BACKLOG' | 'IN PROGRESS' | 'BLOCKED' | 'DONE' => {
  if (!status) {
    return 'BACKLOG';
  }

  const columns: { [key: string]: 'BACKLOG' | 'IN PROGRESS' | 'BLOCKED' | 'DONE' } = {
    backlog: 'BACKLOG',
    in_progress: 'IN PROGRESS',
    blocked: 'BLOCKED',
    done: 'DONE'
  };

  return columns[status];
};

export const getColorForTaskStatus = (status: string) => {
  if (status === 'backlog') {
    return 'blue';
  }
  if (status === 'in_progress') {
    return 'yellow';
  }
  if (status === 'blocked') {
    return 'orange';
  }
  if (status === 'done') {
    return 'green';
  }
  if (status === 'closed') {
    return 'red';
  }

  return 'red';
};
