import { Col, Row, Skeleton, Space, Typography } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import InviteMembersModal from '../components/Projects/InviteMembersModal';
import ProjectHeader from '../components/Projects/ProjectHeader';
import TaskCard from '../components/Tasks/TaskCard';
import TaskModal from '../components/Tasks/TaskModal';
import { getProject, IProject } from '../services/projects';
import { getNonClosedProjectTasks, getTask, ITask } from '../services/tasks';
import { UserContext } from '../store';
import { handleErrorResponse } from '../utilities/errors';
import { statusForColumn } from '../utilities/tasks';
import { isProjectOwner } from '../utilities/projects';

interface IGroupedTasks {
  BACKLOG: ITask[];
  'IN PROGRESS': ITask[];
  BLOCKED: ITask[];
  DONE: ITask[];
}

const resetGroupTasks = () => ({
  BACKLOG: new Array<ITask>(),
  'IN PROGRESS': new Array<ITask>(),
  BLOCKED: new Array<ITask>(),
  DONE: new Array<ITask>()
});

const GroupedTasks = ({
  isLoading,
  tasks,
  onClick
}: {
  isLoading: boolean;
  tasks: ITask[];
  onClick: (task: ITask) => void;
}) => {
  if (isLoading) return <Skeleton active />;

  if (tasks.length === 0) return <></>;

  return (
    <Space direction="vertical" size={10} style={{ width: '100%' }}>
      {tasks.map((task: ITask) => (
        <TaskCard showProject={false} onClick={onClick} key={task.id} task={task} />
      ))}
    </Space>
  );
};

const ProjectDashboard = () => {
  const navigate = useNavigate();
  const { id, taskId } = useParams();
  const [project, setProject] = useState<IProject | null>(null);
  const [tasks, setTasks] = useState<IGroupedTasks>(resetGroupTasks());
  const [selectedTask, setSelectedTask] = useState<ITask | undefined>(undefined);
  const [taskModalOpen, setTaskModalOpen] = useState<boolean>(false);
  const [inviteMembersModalOpen, setInviteMembersModalOpen] = useState<boolean>(false);
  const [isLoadingProject, setIsLoadingProject] = useState(true);
  const [isLoadingTasks, setIsLoadingTasks] = useState(true);
  const { user } = useContext(UserContext);

  const fetchProject = async (id: string | undefined) => {
    if (id === undefined) return;
    setIsLoadingProject(true);
    try {
      const { data } = await getProject(Number(id));
      setProject(data);
      setIsLoadingProject(false);
    } catch (e: any) {
      handleErrorResponse(e);
    }
  };

  const fetchSingleTask = async (id: string | undefined) => {
    if (id === undefined) return;
    setIsLoadingTasks(true);
    try {
      const { data } = await getTask(Number(taskId));
      setSelectedTask(data);
      setTaskModalOpen(true);
    } catch (e: any) {
      handleErrorResponse(e);
    }
  };

  const fetchAndGroupTasks = async (projectId: number) => {
    setIsLoadingTasks(true);
    try {
      const { data } = await getNonClosedProjectTasks(projectId);
      const groupedTasks = resetGroupTasks();
      if (data.length) {
        data.forEach((task: ITask) => {
          if (task.status === 'closed') return;
          groupedTasks[statusForColumn(task.status)].push(task);
        });
        setTasks(groupedTasks);
      } else {
        setTasks(resetGroupTasks());
      }
      setIsLoadingTasks(false);
      await fetchProject(id);
    } catch (e: any) {
      handleErrorResponse(e);
    }
  };

  const handleTaskCardClick = (task: ITask) => {
    navigate(`/dashboard/projects/${id}/tasks/${task.id}`, { replace: true });
    setSelectedTask(task);
    setTaskModalOpen(true);
  };

  useEffect(() => {
    if (!taskModalOpen) {
      navigate(`/dashboard/projects/${id}`, { replace: true });
    }
  }, [taskModalOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetchProject(id);
    fetchAndGroupTasks(Number(id));
    if (taskId) {
      fetchSingleTask(taskId);
    }
  }, [id]); // eslint-disable-line

  return (
    <>
      <TaskModal
        projectId={Number(id)}
        task={selectedTask}
        open={taskModalOpen}
        setOpen={setTaskModalOpen}
        onTaskCreated={() => fetchAndGroupTasks(Number(id))}
        onTaskUpdated={() => {
          fetchAndGroupTasks(Number(id));
          fetchProject(id);
          setSelectedTask(undefined);
        }}
        onTaskDeleted={() => fetchAndGroupTasks(Number(id))}
        isProjectOwner={isProjectOwner(project, user?.id)}
      />

      <InviteMembersModal
        projectId={Number(id)}
        open={inviteMembersModalOpen}
        setOpen={setInviteMembersModalOpen}
      />

      <ProjectHeader
        isLoading={isLoadingProject}
        isProjectOwner={isProjectOwner(project, user?.id)}
        project={project}
        onBack={() => navigate('/dashboard')}
        onInvite={() => {
          setInviteMembersModalOpen(true);
        }}
        onNewTask={() => {
          setSelectedTask(undefined);
          setTaskModalOpen(true);
        }}
      />
      <Row className="project-dashboard-row">
        <Col span={6} className="project-dashboard-column">
          <Typography.Title level={5} style={{ marginBottom: '0.75rem' }}>
            BACKLOG
          </Typography.Title>
          <GroupedTasks
            onClick={handleTaskCardClick}
            isLoading={isLoadingTasks}
            tasks={tasks['BACKLOG']}
          />
        </Col>
        <Col span={6} className="project-dashboard-column">
          <Typography.Title level={5} style={{ marginBottom: '0.75rem' }}>
            IN PROGRESS
          </Typography.Title>
          <GroupedTasks
            onClick={handleTaskCardClick}
            isLoading={isLoadingTasks}
            tasks={tasks['IN PROGRESS']}
          />
        </Col>
        <Col span={6} className="project-dashboard-column">
          <Typography.Title level={5} style={{ marginBottom: '0.75rem' }}>
            BLOCKED
          </Typography.Title>
          <GroupedTasks
            onClick={handleTaskCardClick}
            isLoading={isLoadingTasks}
            tasks={tasks['BLOCKED']}
          />
        </Col>
        <Col span={6} className="project-dashboard-column">
          <Typography.Title level={5} style={{ marginBottom: '0.75rem' }}>
            DONE &nbsp;
            <small>
              <Link to={`/dashboard/projects/${project?.id}/tickets`}>view all tickets</Link>
            </small>
          </Typography.Title>
          <GroupedTasks
            onClick={handleTaskCardClick}
            isLoading={isLoadingTasks}
            tasks={tasks['DONE']}
          />
        </Col>
      </Row>
    </>
  );
};

export default ProjectDashboard;
