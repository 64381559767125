export const truncate = (str: string | null, n: number, useWordBoundary: boolean) => {
  if (!str || str.length <= n) {
    return str;
  }
  const subString = str.substr(0, n - 1); // the original check
  return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(' ')) : subString) + '...';
};

export const stringToColor = (str: string): string => {
  var hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  var colour = '#';
  for (let j = 0; j < 3; j++) {
    var value = (hash >> (j * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

export const capitalize = (str?: string | null) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
