import { FC, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../utilities/api-client';
import { useLocalStorage } from '../utilities/hooks';

interface IinitialState {
  token: string | null;
  isLoggedIn: boolean;
  user?: any;
  setData: (data: any) => void;
  resetData: () => void;
};

const initialState: IinitialState = {
  token: null,
  isLoggedIn: false,
  user: null,
  setData: () => {},
  resetData: () => {},
};

export const UserContext = createContext<IinitialState>(initialState);

export const UserProvider: FC = ({ children }) => {
  // md5sum: nb-tuesday-v1.0.0
  const localStorageKey = 'user-1576bf36d991d82eeef82e3fc2b588ed';
  const [userData, setUserData] = useLocalStorage(localStorageKey, initialState);
  const navigate = useNavigate();

  const cleanup = () => {
    setUserData({ ...initialState });
    navigate('/');
  };

  if (userData.token !== null) {
    axiosInstance.setup(cleanup, userData.token);
  }

  const setData = (data: any) => {
    if (data.token) {
      axiosInstance.setup(cleanup, data.token);
    }
    setUserData({ ...userData, ...data });
  };

  const resetData = () => {
    axiosInstance.resetInterceptors();
    setUserData({ ...initialState });
  };

  return (
    <UserContext.Provider value={{ ...userData, setData, resetData }}>
      { children }
    </UserContext.Provider>
  );
};
