import { useState, useEffect } from 'react';
import {
  SettingOutlined,
  UsergroupAddOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons';
import { PageHeader, Skeleton, Tabs } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { getProject, IProject } from '../services/projects';
import { handleErrorResponse } from '../utilities/errors';
import ProjectBasicDetails from '../components/Projects/ProjectBasicDetails';
import ProjectMembers from '../components/Projects/ProjectMembers';
import CloseProject from '../components/Projects/CloseProject';

const ProjectSettings = () => {
  const { id } = useParams();
  const [project, setProject] = useState<IProject | null>(null);
  const [tabActiveKey, setTabActiveKey] = useState<string>('details');
  const [isLoadingProject, setIsLoadingProject] = useState(true);
  const navigate = useNavigate();

  const fetchProject = async (id: number) => {
    setIsLoadingProject(true);
    try {
      const { data } = await getProject(id);
      setProject(data);
      setIsLoadingProject(false);
    } catch (e: any) {
      handleErrorResponse(e);
    }
  };

  useEffect(() => {
    if (id === undefined) return;
    fetchProject(parseInt(id, 10));
  }, [id]);

  return (
    <>
      <PageHeader
        title="Project Settings"
        style={{
          paddingLeft: 0,
          paddingTop: 0,
          paddingRight: 0
        }}
        onBack={() => navigate(`/dashboard/projects/${id}`)}
      />
      {isLoadingProject ? (
        <Skeleton active />
      ) : (
        <Tabs
          tabPosition="left"
          activeKey={tabActiveKey}
          onChange={(key) => {
            setTabActiveKey(key);
          }}
        >
          <Tabs.TabPane
            tab={
              <>
                <SettingOutlined /> Basic Details
              </>
            }
            key="details"
          >
            {project !== null && <ProjectBasicDetails project={project} />}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <>
                <UsergroupAddOutlined /> Members
              </>
            }
            key="members"
          >
            {project !== null && (
              <ProjectMembers
                members={project.members}
                projectId={project.id}
                onProjectMemberDeleted={() => fetchProject(project.id)}
              />
            )}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab={
              <>
                <ExclamationCircleOutlined /> Danger Zone
              </>
            }
            key="danger-zone"
          >
            {project !== null && <CloseProject projectId={project?.id} />}
          </Tabs.TabPane>
        </Tabs>
      )}
    </>
  );
};

export default ProjectSettings;
