import { ClockCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Badge, Card, Space, Tag } from 'antd';
import { ITask } from '../../services/tasks';
import { getColourForType, ribbonForType } from '../../utilities/tasks';
import TaskPriorityIcon from './TaskPriorityIcon';

const { Meta } = Card;

interface ITaskCardProps {
  task: ITask;
  showProject?: boolean;
  onClick: (task: ITask) => void;
}

const TaskCard = ({ task, showProject = true, onClick }: ITaskCardProps) => {
  return (
    <Badge.Ribbon
      text={ribbonForType(task.type)}
      style={{
        borderTopRightRadius: '4px',
        borderTopLeftRadius: '4px',
        borderBottomLeftRadius: '4px'
      }}
      color={getColourForType(task.type)}
    >
      <Card
        onClick={() => onClick(task)}
        hoverable
        style={{
          padding: '0.5rem',
          borderRadius: '5px',
          border: '1px solid #e7eaef'
        }}
        size="small"
      >
        <Meta
          title={
            <span style={{ whiteSpace: 'pre-wrap', fontSize: '0.95rem', wordBreak: 'break-all' }}>
              {task?.title || ''}
            </span>
          }
          style={{ marginBottom: '0.75rem', paddingRight: '2rem' }}
        />
        <TaskPriorityIcon taskPriority={task.priority}></TaskPriorityIcon>

        <Space style={{ whiteSpace: 'pre-wrap', float: 'right' }} direction="horizontal" size={3}>
          {showProject && <Tag color="blue">{task.project.title}</Tag>}
          {task.hours > 0 && (
            <Tag icon={<ClockCircleOutlined />} color="default">
              {`${task.hours}h`}
            </Tag>
          )}
          <Tag style={{ marginRight: 0 }} icon={<UserOutlined />} color="default">
            {task?.assignee?.name || 'Unassigned'}
          </Tag>
        </Space>
      </Card>
    </Badge.Ribbon>
  );
};

export default TaskCard;
