import {
  CaretDownOutlined,
  HomeOutlined,
  LogoutOutlined,
  ProfileOutlined
} from '@ant-design/icons';
import { Layout, Menu, Space } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import { MouseEvent, useState, useContext } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import AccountSettingsModal from '../components/Users/AccountSettingsModal';
import { logout } from '../services/auth';
import { UserContext } from '../store';
import { stringToColor } from '../utilities/strings';

const { Header, Content, Footer } = Layout;

const DashboardLayout = () => {
  const navigate = useNavigate();
  const [accountSettingsModalOpen, setAccountSettingsModalOpen] = useState(false);
  const { resetData, user } = useContext(UserContext);

  const signOut = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    logout().then(() => {
      resetData();
      navigate('/');
    });
  };

  const openAccountSettings = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setAccountSettingsModalOpen(true);
  };

  return (
    <Layout className="dashboard-layout">
      <Header>
        <Link to="/dashboard">
          <div className="logo-topbar" />
        </Link>
        <Menu theme="dark" mode="horizontal">
          <Menu.Item style={{ float: 'left' }} icon={<HomeOutlined />} key="dashboard">
            <Link to="/dashboard">Dashboard</Link>
          </Menu.Item>
          {user && (
            <Menu.SubMenu
              style={{ float: 'right', marginLeft: 'auto', paddingRight: 0 }}
              key="settings"
              popupOffset={[-30, 0]}
              title={
                <Space direction="horizontal">
                  <Avatar style={{ backgroundColor: stringToColor(user?.name) }}>
                    {user?.name.charAt(0)}
                  </Avatar>
                  <span>{user?.name}</span>
                  <CaretDownOutlined />
                </Space>
              }
            >
              <Menu.Item icon={<ProfileOutlined />} key="account-settings">
                <Link to="/" onClick={openAccountSettings}>Account Settings</Link>
              </Menu.Item>
              <Menu.Item icon={<LogoutOutlined />} key="logout">
                <Link to="/" onClick={signOut}>
                  Logout
                </Link>
              </Menu.Item>
            </Menu.SubMenu>
          )}
        </Menu>
      </Header>
      <Content style={{ margin: '0 3rem' }}>
        <div style={{ padding: '2rem' }} className="site-layout-content">
          <Outlet />
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        Made with {'❤️'}&nbsp; by NoeticBlue | All rights reserved ©{' ' + new Date().getFullYear()}
      </Footer>
      <AccountSettingsModal open={accountSettingsModalOpen} setOpen={setAccountSettingsModalOpen} />
    </Layout>
  );
};

export default DashboardLayout;
