import { UpSquareTwoTone } from '@ant-design/icons';
import { Tag, Popover } from 'antd';
import { TaskPriorityType } from '../../services/tasks';

interface ITaskPriorityProps {
  taskPriority: TaskPriorityType;
}

const TaskPriorityIcon = ({ taskPriority }: ITaskPriorityProps) => {
  const setIconForPriority = (taskPriority: string) => {
    if (taskPriority === 'low') {
      return <UpSquareTwoTone twoToneColor="orange"></UpSquareTwoTone>;
    }
    if (taskPriority === 'medium') {
      return (
        <>
          <UpSquareTwoTone twoToneColor="orange"></UpSquareTwoTone>
          <UpSquareTwoTone twoToneColor="orange"></UpSquareTwoTone>
        </>
      );
    }
    if (taskPriority === 'high') {
      return (
        <>
          <UpSquareTwoTone twoToneColor="orange"></UpSquareTwoTone>
          <UpSquareTwoTone twoToneColor="orange"></UpSquareTwoTone>
          <UpSquareTwoTone twoToneColor="orange"></UpSquareTwoTone>
        </>
      );
    }
    return <></>;
  };

  return (
    <Popover content={'Priority: ' + taskPriority}>
      <Tag style={{ marginLeft: '-0.5rem' }} color="white">
        {setIconForPriority(taskPriority)}
      </Tag>
    </Popover>
  );
};

export default TaskPriorityIcon;
