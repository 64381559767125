import { Form, Select } from 'antd';
import { ITaskPriority } from '../../services/tasks';

interface ITaskPrioritiesProps {
  isLoading: boolean;
  priorities: ITaskPriority[];
}

const TaskPriorities = ({ isLoading, priorities }: ITaskPrioritiesProps) => {
  return (
    <Form.Item name="priority" label="Task priority">
      <Select
        loading={isLoading}
        showSearch
        allowClear
        placeholder="Set priority"
        options={priorities}
        filterOption={(input, option: any) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
    </Form.Item>
  );
};

export default TaskPriorities;
