import apiClient from '../utilities/api-client';

export interface IUser {
  id: number;
  name: string;
  role: string;
  email: string;
}

export interface IUpdateMeRequest {
  name: string;
  password?: string;
  password_confirmation?: string;
}

export const searchUsers = async (query: string) =>
  apiClient.get(`/users`, { params: { search: query } });

export const updateMe = async (data: IUpdateMeRequest) => apiClient.put(`/users/me`, data);
