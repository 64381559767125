/* eslint-disable no-console */
import axios from 'axios';
import { handleErrorResponse } from './errors';

const singletonEnforcer = Symbol('API Singleton Enforcer');

class ApiClient {
  private static instance: ApiClient;

  isInitialised: boolean = false;
  requestInterceptors: any = [];
  responseInterceptors: any = [];
  cancellationSource: any = null;
  session: any;
  singleton: any;

  constructor(enforcer: any) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton');
    }

    // eslint-disable-next-line
    console.log(`Initialized API client for: ${process.env.REACT_APP_API_BASE_URL}`);

    this.session = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      timeout: Number(process.env.REACT_APP_REQUEST_TIMEOUT) * 1000,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    });
  }

  public static getInstance(): ApiClient {
    // Try to get an efficient singleton
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient(singletonEnforcer);
    }

    return ApiClient.instance;
  }

  setup = (cb: any, token: any) => {
    if (this.isInitialised) return;

    this.cancellationSource = axios.CancelToken.source();

    console.log(`Setting up API client interceptors.`);
    this.responseInterceptors.push(
      this.session.interceptors.response.use(
        (response: any) => (response.data ? response.data : response),
        (error: any) => {
          if (axios.isCancel(error)) return () => {};
          if (error && error.response && error.response.status === 401) {
            this.resetInterceptors();
            handleErrorResponse(error);
            return cb();
          }
          return Promise.reject(error);
        }
      )
    );

    this.requestInterceptors.push(
      this.session.interceptors.request.use((config: any) => {
        config.headers['Authorization'] = `Bearer ${token}`; //eslint-disable-line
        return config;
      })
    );

    this.isInitialised = true;
  };

  resetInterceptors = () => {
    console.log(`Resetting API client interceptors.`);
    this.requestInterceptors.forEach((i: any) => {
      this.session.interceptors.request.eject(i);
    });

    this.responseInterceptors.forEach((i: any) => {
      this.session.interceptors.response.eject(i);
    });

    if (this.cancellationSource) {
      console.log(`Cancelling running requests.`);
      this.cancellationSource.cancel();
    }

    this.isInitialised = false;
    setTimeout(() => {
      this.cancellationSource = null;
    }, 1500);
  };

  get = (...params: any) =>
    this.session.get(...params, {
      cancelToken: this.cancellationSource ? this.cancellationSource.token : null
    });

  post = (...params: any) =>
    this.session.post(...params, {
      cancelToken: this.cancellationSource ? this.cancellationSource.token : null
    });

  put = (...params: any) =>
    this.session.put(...params, {
      cancelToken: this.cancellationSource ? this.cancellationSource.token : null
    });

  patch = (...params: any) =>
    this.session.patch(...params, {
      cancelToken: this.cancellationSource ? this.cancellationSource.token : null
    });

  remove = (...params: any) =>
    this.session.delete(...params, {
      cancelToken: this.cancellationSource ? this.cancellationSource.token : null
    });
}

export default ApiClient.getInstance();

export interface APIPaginationMeta {
  current_page: number;
  from: number;
  last_page: number;
  per_page: number;
  to: number;
  total: number;
  sum_hours: number;
}

export const mapPaginationMeta = (apiPaginationMeta: APIPaginationMeta) => ({
  pageSize: apiPaginationMeta.per_page,
  current: apiPaginationMeta.current_page,
  total: apiPaginationMeta.total,
  sum_hours: apiPaginationMeta?.sum_hours
});
