import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InviteMembersModal from '../components/Projects/InviteMembersModal';
import TaskPriorityIcon from '../components/Tasks/TaskPriorityIcon';
import ProjectHeader from '../components/Projects/ProjectHeader';
import { Tag, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TaskModal from '../components/Tasks/TaskModal';
import { getProject, IProject } from '../services/projects';
import { APIPaginationMeta, mapPaginationMeta } from '../utilities/api-client';
import { getProjectTasks, ITask, TaskFilters } from '../services/tasks';
import { UserContext } from '../store';
import { handleErrorResponse } from '../utilities/errors';
import { isProjectOwner } from '../utilities/projects';
import { getColorForTaskStatus } from '../utilities/tasks';

const ProjectTickets = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoadingProject, setIsLoadingProject] = useState(true);
  const [isLoadingTasks, setIsLoadingTasks] = useState(true);
  const [project, setProject] = useState<IProject | null>(null);
  const [inviteMembersModalOpen, setInviteMembersModalOpen] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<ITask | undefined>(undefined);
  const [tasksMeta, setTasksMeta] = useState<APIPaginationMeta | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const [tableIsFiltered, setTableIsFiltered] = useState<boolean>(false);
  const [taskModalOpen, setTaskModalOpen] = useState<boolean>(false);
  const [tasks, setTasks] = useState<Array<ITask>>();
  const { user } = useContext(UserContext);

  const fetchProject = async (id: string | undefined) => {
    if (id === undefined) return;
    setIsLoadingProject(true);
    try {
      const { data } = await getProject(Number(id));
      setProject(data);
      setIsLoadingProject(false);
    } catch (e: any) {
      handleErrorResponse(e);
    }
  };

  const fetchProjectTasks = async (
    projectId: number,
    page: number,
    filters: TaskFilters | {} = {}
  ) => {
    setIsLoadingTasks(true);
    try {
      const { data, meta } = await getProjectTasks(projectId, page, filters);
      if (meta) {
        setTasksMeta(meta);
      }

      setTasks(data);
      setIsLoadingTasks(false);
    } catch (e: any) {
      handleErrorResponse(e);
    }
  };

  useEffect(() => {
    fetchProject(id);
  }, [id]);

  useEffect(() => {
    fetchProjectTasks(Number(id), currentPage);
  }, [id, currentPage]);

  const handleTaskClick = (task: ITask) => {
    setSelectedTask(task);
    setTaskModalOpen(true);
  };

  const footer = (tableIsFiltered: boolean, sumHours: Number | undefined) => {
    if (tableIsFiltered) {
      return (
        <Tag>
          <b>Sum of hours for these filtered tasks: {sumHours} </b>
        </Tag>
      );
    }
  };

  const columns: ColumnsType<ITask> = [
    {
      title: 'Task',
      key: 'Task',
      render: (_text: string, record: ITask, _index: number) => (
        <Space direction="vertical">
          <Typography.Text
            key="title"
            strong
            underline
            onClick={() => handleTaskClick(record)}
            style={{ cursor: 'pointer' }}
          >
            {record.title}
            <br></br>
            <TaskPriorityIcon taskPriority={record.priority}></TaskPriorityIcon>
          </Typography.Text>
        </Space>
      ),
      width: '30%'
    },
    {
      title: 'Assignee',
      key: 'assignee',
      render: (_text: string, record: ITask, _index: number) => (
        <Space direction="vertical">
          <Typography.Text key="assignee" strong>
            <Tag>{record.assignee?.name}</Tag>
          </Typography.Text>
        </Space>
      ),
      width: '30%'
    },
    {
      title: 'Hours',
      key: 'hours',
      render: (_text: string, record: ITask, _index: number) => (
        <Space direction="vertical">
          <Typography.Text key="hours" strong>
            <Tag>{record.hours} hours</Tag>
          </Typography.Text>
        </Space>
      ),
      width: '20%'
    },
    {
      title: 'Status',
      key: 'status',
      render: (_text: string, record: ITask, _index: number) => (
        <Space direction="vertical">
          <Typography.Text key="role" strong>
            <Tag color={getColorForTaskStatus(record.status)}>{record.status.toUpperCase()}</Tag>
          </Typography.Text>
        </Space>
      ),
      width: '20%',
      filters: [
        { text: 'Backlog', value: 'backlog' },
        { text: 'Done', value: 'done' },
        { text: 'In Progress', value: 'in_progress' },
        { text: 'Blocked', value: 'blocked' },
        { text: 'Closed', value: 'closed' }
      ]
    }
  ];

  return (
    <>
      <TaskModal
        projectId={Number(id)}
        task={selectedTask}
        open={taskModalOpen}
        setOpen={setTaskModalOpen}
        onTaskCreated={() => fetchProjectTasks(Number(id), currentPage)}
        onTaskUpdated={() => {
          fetchProjectTasks(Number(id), currentPage);
          fetchProject(id);
          setSelectedTask(undefined);
        }}
        onTaskDeleted={() => fetchProjectTasks(Number(id), currentPage)}
        isProjectOwner={isProjectOwner(project, user?.id)}
      />
      <InviteMembersModal
        projectId={Number(id)}
        open={inviteMembersModalOpen}
        setOpen={setInviteMembersModalOpen}
      />
      <ProjectHeader
        isLoading={isLoadingProject}
        isProjectOwner={isProjectOwner(project, user?.id)}
        project={project}
        onBack={() => navigate(`/dashboard/projects/${id}`)}
        onInvite={() => {
          setInviteMembersModalOpen(true);
        }}
        onNewTask={() => {
          setSelectedTask(undefined);
          setTaskModalOpen(true);
        }}
      />

      <Table<ITask>
        bordered
        loading={isLoadingTasks}
        rowKey={(record: ITask) => record.id}
        pagination={
          tasksMeta !== undefined
            ? {
                ...mapPaginationMeta(tasksMeta),
                onChange: (page: number) => setCurrentPage(page),
                hideOnSinglePage: true,
                showSizeChanger: false
              }
            : {}
        }
        dataSource={tasks}
        onChange={(pagination, filters, sorter, extra) => {
          if (filters.status !== null) {
            setTableIsFiltered(true);
            // filterValue may be boolean,number or string
            fetchProjectTasks(Number(id), currentPage, { status: filters.status.toString() });
          } else {
            setTableIsFiltered(false);
            fetchProjectTasks(Number(id), currentPage);
          }
        }}
        columns={columns}
        footer={() => footer(tableIsFiltered, tasksMeta?.sum_hours)}
      />
    </>
  );
};

export default ProjectTickets;
