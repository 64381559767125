import { DeleteOutlined } from '@ant-design/icons';
import { Avatar, Button, Space, Table, Typography, message } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IProjectMember } from '../../services/projects';
import { stringToColor } from '../../utilities/strings';
import { Tag } from 'antd';
import { setColorForRole } from '../../utilities/projects';
import { UserContext } from '../../store';
import { useContext } from 'react';
import { deleteMember } from '../../services/projects';
import { handleErrorResponse } from '../../utilities/errors';

interface IProjecMemberTableProps {
  members: Array<IProjectMember>;
  projectId: number;
  onProjectMemberDeleted: Function;
}

const ProjectMembers = ({
  members,
  projectId,
  onProjectMemberDeleted
}: IProjecMemberTableProps) => {
  const { user } = useContext(UserContext);
  const deleteMemberOfProject = async (projectId: number, memberId: number) => {
    try {
      await deleteMember(projectId, memberId);
      onProjectMemberDeleted();
      message.success('Member removed successfully.');
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const columns: ColumnsType<IProjectMember> = [
    {
      title: 'Members',
      key: 'members',
      render: (_text: string, record: IProjectMember, _index: number) => (
        <Space direction="vertical">
          <Typography.Text key="title" strong style={{ cursor: 'pointer' }}>
            <Avatar
              alt={record.name}
              key={record.id}
              style={{ backgroundColor: stringToColor(record.name), marginRight: '0.5rem' }}
            >
              {record.name.charAt(0)}
            </Avatar>
            {record.name}
          </Typography.Text>
        </Space>
      ),
      width: '40%'
    },
    {
      title: 'Role',
      key: 'role',
      render: (_text: string, record: IProjectMember, _index: number) => (
        <Space direction="vertical">
          <Typography.Text key="role" strong style={{ cursor: 'pointer' }}>
            <Tag color={setColorForRole(record.role)}>{record.role.toUpperCase()}</Tag>
          </Typography.Text>
        </Space>
      ),
      width: '30%'
    },
    {
      title: 'E-mail',
      key: 'email',
      render: (_text: string, record: IProjectMember, _index: number) => (
        <Space direction="vertical">
          <Typography.Text key="title" strong style={{ cursor: 'pointer' }}>
            {record.email}
          </Typography.Text>
        </Space>
      ),
      width: '40%'
    },
    {
      title: 'Delete Member',
      key: 'deletemember',
      render: (_text: string, record: IProjectMember, _index: number) => (
        <Space direction="horizontal">
          {record?.id !== user?.id && (
            <Button
              key="open"
              icon={<DeleteOutlined />}
              type="default"
              onClick={(e) => {
                e.preventDefault();
                deleteMemberOfProject(projectId, record?.id);
              }}
              style={{ marginRight: '0.5rem' }}
              danger
            >
              Remove Member
            </Button>
          )}
        </Space>
      ),
      width: '30%'
    }
  ];

  return (
    <Table<IProjectMember>
      bordered
      rowKey={(record: IProjectMember) => record.id}
      dataSource={members}
      columns={columns}
      pagination={false}
    />
  );
};

export default ProjectMembers;
