import apiClient from '../utilities/api-client';

export interface IProjectMember {
  id: number;
  name: string;
  email: string;
  role: string;
}

export type TProjectStatus = 'open' | 'closed' | undefined;

export interface ICreateProjectParams {
  title: string;
  description?: string;
  cost_per_hour?: number;
  estimated_hours?: number;
  started_at?: Date;
  delivered_at?: Date;
}

export interface IProject {
  id: number;
  title: string;
  hours: number;
  description: string | null;
  members: [IProjectMember];
  cost_per_hour: number | null;
  estimated_hours: number | null;
  started_at: Date | null;
  delivered_at: Date | null;
  status: TProjectStatus;
}

export interface IUpdateProjectParams {
  title: string;
  description?: string;
  cost_per_hour?: number;
  estimated_hours?: number;
  started_at?: Date;
  delivered_at?: Date;
}

export const listProjects = async (page: number = 1, per_page: number = 15) =>
  apiClient.get(`/projects?page=${page}&per_page=${per_page}`);

export const getProject = async (projectId: number) => apiClient.get(`/projects/${projectId}`);

export const createProject = async (project: ICreateProjectParams) =>
  apiClient.post('/projects', project);

export const updateProject = async (id: number | undefined, project: IUpdateProjectParams) =>
  apiClient.put(`/projects/${id}`, project);

export const deleteProject = async (id: number) => apiClient.remove(`/projects/${id}`);

export const closeProject = async (id: number) => apiClient.post(`/projects/${id}/close`);

export const listProjectMembers = async (id: number) => apiClient.get(`/projects/${id}/members`);

export const inviteMember = async (id: number, memberId: number) =>
  apiClient.post(`/projects/${id}/members`, { user_id: memberId });

export const deleteMember = async (projectId: number, memberId: number) =>
  apiClient.remove(`/projects/${projectId}/members/${memberId}`);
