import { UserAddOutlined } from '@ant-design/icons';
import { AutoComplete, Button, Form, Input, message, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { inviteMember } from '../../services/projects';
import { IUser, searchUsers } from '../../services/users';
import { handleErrorResponse } from '../../utilities/errors';
import { useDebounce } from '../../utilities/hooks';

interface IInviteMembersModalProps {
  projectId: number | undefined;
  open: boolean;
  setOpen: Function;
  onInvited?: Function;
}

const InviteMembersModal = ({ projectId, open, setOpen, onInvited }: IInviteMembersModalProps) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [query, setQuery] = useState<string>('');
  const [userResults, setUserResults] = useState<Array<IUser>>([]);
  const debouncedSearch = useDebounce(query, 500);

  const onClose = () => {
    form.resetFields();
    setOpen(false);
  };

  const handleSearch = (value: string) => {
    setQuery(value);
  };

  const search = async () => {
    try {
      const { data } = await searchUsers(query);
      setUserResults(data);
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const onSubmit = async (values: any) => {
    if (!projectId) return;
    setIsSubmitting(true);
    const { user_id } = values;
    try {
      await inviteMember(projectId, Number(user_id));
      message.success('Member has been added to the project.');
      form.resetFields();
      setOpen(false);
      if (onInvited) onInvited();
    } catch (error) {
      handleErrorResponse(error);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    if (query.length > 2) {
      search();
    }
  }, [debouncedSearch]); // eslint-disable-line

  return (
    <Modal onCancel={onClose} title="Invite Members" footer={null} visible={open}>
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Form.Item name="user" label="User to invite">
          <AutoComplete
            autoFocus
            onSearch={handleSearch}
            onSelect={(value: string, option: any) => {
              form.setFieldsValue({
                user_id: value,
                user: option.children
              });
            }}
            placeholder="Search users via email or name..."
            notFoundContent="No users found matching these criteria"
          >
            {userResults.map(({ id, name, email }: IUser) => (
              <AutoComplete.Option key={id} value={`${id}`}>
                {`${name} (${email})`}
              </AutoComplete.Option>
            ))}
          </AutoComplete>
        </Form.Item>
        <Form.Item name="user_id" hidden>
          <Input />
        </Form.Item>

        <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
          <Button
            icon={<UserAddOutlined />}
            type="primary"
            loading={isSubmitting}
            htmlType="submit"
          >
            Invite
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default InviteMembersModal;
