import { Form, Select } from 'antd';
import { ITaskStatus } from '../../services/tasks';

interface ITaskStatusesProps {
  isLoading: boolean;
  statuses: ITaskStatus[];
}

const TaskStatuses = ({ isLoading, statuses }: ITaskStatusesProps) => {
  return (
    <Form.Item name="status" label="Task status">
      <Select
        loading={isLoading}
        showSearch
        allowClear
        placeholder="Choose a status"
        options={statuses}
        filterOption={(input, option: any) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
    </Form.Item>
  );
};

export default TaskStatuses;
