import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, PageHeader, Row, Skeleton, Space } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProjectModal from '../components/Projects/ProjectModal';
import InviteMembersModal from '../components/Projects/InviteMembersModal';
import ProjectsTable from '../components/Projects/ProjectsTable';
import TaskCard from '../components/Tasks/TaskCard';
import TaskModal from '../components/Tasks/TaskModal';
import { IProject, listProjects } from '../services/projects';
import { ITask, listTasks } from '../services/tasks';
import { APIPaginationMeta } from '../utilities/api-client';
import { handleErrorResponse } from '../utilities/errors';
import { UserContext } from '../store';

const Dashboard = () => {
  const navigate = useNavigate();
  const [projectModalOpen, setProjectModalOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [tasks, setTasks] = useState<ITask[] | []>([]);
  const [selectedTask, setSelectedTask] = useState<ITask | undefined>(undefined);
  const [selectedProject, setSelectedProject] = useState<IProject | undefined>(undefined);
  const [taskModalOpen, setTaskModalOpen] = useState<boolean>(false);
  const [inviteMembersModalOpen, setInviteMembersModalOpen] = useState<boolean>(false);
  const [projectsMeta, setProjectsMeta] = useState<APIPaginationMeta | undefined>();
  const [currentPage, setCurrentPage] = useState(1);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [tasksLoading, setTasksLoading] = useState(true);
  const { user } = useContext(UserContext);

  const fetchTasks = async () => {
    setTasksLoading(true);
    try {
      const { data } = await listTasks();
      setTasks(data);
    } catch (e: any) {
      handleErrorResponse(e);
    }
    setTasksLoading(false);
  };

  const fetchProjects = async (page: number) => {
    setProjectsLoading(true);
    try {
      const { data, meta } = await listProjects(page);
      setProjects(data);
      if (meta) {
        setProjectsMeta(meta);
      }
    } catch (e: any) {
      handleErrorResponse(e);
    }
    setProjectsLoading(false);
  };

  useEffect(() => {
    fetchTasks();
  }, []);

  useEffect(() => {
    if (!projectModalOpen) {
      fetchProjects(currentPage);
    }
  }, [projectModalOpen, currentPage]);

  const handleTaskCardClick = (task: ITask) => {
    setSelectedTask(task);
    setTaskModalOpen(true);
  };

  const handleInviteMembers = (project: IProject) => {
    setSelectedProject(project);
    setInviteMembersModalOpen(true);
  };

  const openProject = (id: number) => {
    navigate(`/dashboard/projects/${id}`);
  };

  return (
    <Row>
      <TaskModal
        projectId={selectedTask?.project.id}
        task={selectedTask}
        open={taskModalOpen}
        setOpen={setTaskModalOpen}
        onTaskCreated={() => {}}
        onTaskUpdated={() => {
          fetchTasks();
          setSelectedTask(undefined);
        }}
      />
      <InviteMembersModal
        projectId={selectedProject?.id}
        open={inviteMembersModalOpen}
        setOpen={setInviteMembersModalOpen}
        onInvited={() => fetchProjects(1)}
      />
      <ProjectModal open={projectModalOpen} setOpen={setProjectModalOpen} />
      <Col xs={24} lg={8}>
        <PageHeader
          style={{ paddingLeft: 0, paddingTop: 0 }}
          title="My Open Tasks"
          subTitle={`In total: ${tasks.length} open tasks assigned to you.`}
        />
        <Space
          direction="vertical"
          size={20}
          style={{ width: '100%', maxHeight: '100vh', overflow: 'auto', paddingRight: '10px' }}
        >
          {tasksLoading ? (
            <Skeleton active />
          ) : (
            <>
              {tasks.map((t) => (
                <TaskCard onClick={() => handleTaskCardClick(t)} task={t} key={t.id} />
              ))}
            </>
          )}
        </Space>
      </Col>
      <Col xs={{ span: 24, offset: 0 }} lg={{ span: 15, offset: 1 }}>
        <PageHeader
          style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0 }}
          title="Projects"
          subTitle={`You're currently engaged in ${projectsMeta?.total} projects.`}
          extra={[
            <Button
              type="primary"
              icon={<PlusOutlined />}
              key="1"
              onClick={() => setProjectModalOpen(true)}
            >
              New Project
            </Button>
          ]}
        />
        <ProjectsTable
          loading={projectsLoading}
          paginationMeta={projectsMeta}
          loggedInUserId={user?.id}
          openProject={openProject}
          inviteMembers={handleInviteMembers}
          onPage={(page: number) => setCurrentPage(page)}
          projects={projects}
        />
      </Col>
    </Row>
  );
};

export default Dashboard;
