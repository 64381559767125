import { Route, Routes } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import ProjectDashboard from './pages/ProjectDashboard';
import ProjectSettings from './pages/ProjectSettings';
import ProjectTickets from './pages/ProjectTickets';
import Register from './pages/Register';
import { UserProvider } from './store';

const App = () => {
  return (
    <UserProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route index element={<Dashboard />} />
          <Route path="/dashboard/projects/:id" element={<ProjectDashboard />} />
          <Route path="/dashboard/projects/:id/tasks/:taskId" element={<ProjectDashboard />} />
          <Route path="/dashboard/projects/:id/tickets" element={<ProjectTickets />} />
          <Route path="/dashboard/projects/:id/settings" element={<ProjectSettings />} />
        </Route>
      </Routes>
    </UserProvider>
  );
};

export default App;
