import { Badge, Tag } from 'antd';
import { TProjectStatus } from '../../services/projects';
import { getColorForStatus } from '../../utilities/projects';
import { capitalize } from '../../utilities/strings';

interface IProjectStatusProps {
  status: TProjectStatus;
}

const ProjectStatus = ({ status }: IProjectStatusProps) => {
  return (
    <Tag key="status" color={getColorForStatus(status)}>
      <Badge color={getColorForStatus(status)} />
      {capitalize(status)}
    </Tag>
  );
};

export default ProjectStatus;
