import { FolderOpenOutlined, UserAddOutlined } from '@ant-design/icons';
import { Avatar, Button, Empty, Space, Table, Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { IProject } from '../../services/projects';
import { APIPaginationMeta, mapPaginationMeta } from '../../utilities/api-client';
import { stringToColor, truncate } from '../../utilities/strings';
import ProjectStatus from './ProjectStatus';
import { isProjectOwner } from '../../utilities/projects';

interface IProjectsTableProps {
  projects: Array<IProject>;
  paginationMeta?: APIPaginationMeta;
  onPage: (page: number, pageSize: number) => void;
  openProject: (id: number) => void;
  inviteMembers: (project: IProject) => void;
  loading: boolean;
  loggedInUserId: Number | undefined;
}

const ProjectsTable = ({
  projects,
  paginationMeta,
  onPage,
  loading,
  openProject,
  loggedInUserId,
  inviteMembers
}: IProjectsTableProps) => {
  if (projects.length === 0 && !loading) {
    return <Empty description="No projects found" style={{ marginTop: '2rem' }} />;
  }

  const columns: ColumnsType<IProject> = [
    {
      title: 'Project',
      key: 'title',
      render: (_text: string, record: IProject, _index: number) => (
        <Space direction="vertical">
          <Typography.Text
            key="title"
            strong
            underline
            style={{ cursor: 'pointer' }}
            onClick={() => openProject(record.id)}
          >
            {record.title}
          </Typography.Text>
          <Space direction="horizontal">
            <ProjectStatus status={record.status} />
            <Typography.Text key="description" type="secondary" italic>
              {truncate(record?.description || '', 50, true)}
            </Typography.Text>
          </Space>
        </Space>
      ),
      width: '50%'
    },
    {
      title: 'Project Members',
      key: 'members',
      render: (_text: string, record: IProject, _index: number) => (
        <Avatar.Group maxCount={5} maxStyle={{ color: '#ffffff', backgroundColor: '#0f256d' }}>
          {record?.members?.map((m) => (
            <Avatar alt={m.name} key={m.id} style={{ backgroundColor: stringToColor(m.name) }}>
              {m.name.charAt(0)}
            </Avatar>
          ))}
        </Avatar.Group>
      ),
      width: '20%'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_text: string, record: IProject, _index: number) => (
        <Space direction="horizontal">
          <Button
            key="open"
            icon={<FolderOpenOutlined />}
            type="default"
            onClick={() => openProject(record.id)}
          >
            Open
          </Button>

          {isProjectOwner(record, loggedInUserId) && (
            <Button
              key="invite-members"
              icon={<UserAddOutlined />}
              type="default"
              onClick={() => inviteMembers(record)}
            >
              Invite Members
            </Button>
          )}
        </Space>
      ),
      width: '30%'
    }
  ];

  return (
    <Table<IProject>
      bordered
      loading={loading}
      rowKey={(record: IProject) => record.id}
      dataSource={projects}
      columns={columns}
      pagination={
        paginationMeta !== undefined
          ? {
              ...mapPaginationMeta(paginationMeta),
              onChange: onPage,
              hideOnSinglePage: true,
              showSizeChanger: false
            }
          : {}
      }
    />
  );
};

export default ProjectsTable;
