import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  PlusOutlined,
  SettingOutlined,
  UserAddOutlined
} from '@ant-design/icons';
import { Button, Descriptions, PageHeader, Progress, Skeleton, Space, Statistic } from 'antd';
import { differenceInDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { IProject } from '../../services/projects';
import ProjectStatus from './ProjectStatus';

interface IProjectHeaderProps {
  isLoading: boolean;
  project: IProject | null;
  onNewTask: Function;
  onInvite: Function;
  isProjectOwner: boolean;
  onBack: Function;
}

const ProjectHeader = ({
  isLoading,
  project,
  onInvite,
  onNewTask,
  onBack,
  isProjectOwner
}: IProjectHeaderProps) => {
  const navigate = useNavigate();

  if (isLoading) return <Skeleton active />;

  // Display nothing if project is null
  if (!project) return <></>;

  const renderDaysLeftProgressBar = (project: IProject) => {
    let percent = 0;
    let message = 'N/A';

    if (project.started_at && project.delivered_at) {
      // Find the diff in days
      const totalDiffInDays = differenceInDays(
        new Date(project.delivered_at),
        new Date(project.started_at)
      );
      const daysUntilNow = differenceInDays(new Date(), new Date(project.started_at));
      percent = Math.round((daysUntilNow * 100) / totalDiffInDays);
      message = `${totalDiffInDays - daysUntilNow} days left`;
    }

    return <Progress percent={percent} steps={10} format={() => `${message}`} />;
  };

  const renderHoursLeftProgressBar = (project: IProject) => {
    let percent = 0;
    let message = 'N/A';

    if (project.estimated_hours) {
      percent = Math.round((Number(project.hours) * 100) / Number(project.estimated_hours));
      message = `${project.hours} / ${project.estimated_hours} hours`;
    }

    return <Progress percent={percent} steps={10} format={() => `${message}`} />;
  };

  const amountDiff =
    project.cost_per_hour && project.estimated_hours
      ? project.estimated_hours * project.cost_per_hour - project.hours * project.cost_per_hour
      : 0;

  const renderAmountDiffIcon = (amountDiff: number) => {
    if (amountDiff === 0) return <></>;
    return amountDiff > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />;
  };

  const amountDiffColor = (amountDiff: number) => {
    if (amountDiff === 0) return 'rgba(0,0,0,.85)';
    return amountDiff > 0 ? '#3f8600' : '#cf1322';
  };

  return (
    <PageHeader
      className="site-page-header-responsive"
      style={{
        paddingLeft: 0,
        paddingTop: 0,
        paddingRight: 0
      }}
      title={project?.title}
      subTitle={<ProjectStatus status={project?.status} />}
      onBack={() => onBack()}
      extra={[
        <Space key="action-buttons" direction="horizontal">
          {isProjectOwner && (
            <Button
              key="settings"
              type="default"
              icon={<SettingOutlined />}
              onClick={() => {
                navigate(`/dashboard/projects/${project.id}/settings`);
              }}
            >
              Settings
            </Button>
          )}
          {project?.status === 'open' && (
            <>
              {isProjectOwner && (
                <Button
                  key="invite-members"
                  icon={<UserAddOutlined />}
                  type="default"
                  onClick={() => onInvite()}
                >
                  Invite Members
                </Button>
              )}
              <Button
                key="new-task"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => onNewTask()}
              >
                New task
              </Button>
            </>
          )}
        </Space>
      ]}
    >
      <div className="content" style={{ display: 'flex' }}>
        <div className="main">
          <Descriptions size="small" column={3}>
            <Descriptions.Item label="Project Started" contentStyle={{ fontWeight: 'bold' }}>
              {project.started_at ? new Date(project.started_at).toLocaleDateString() : '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Estimated Hours" contentStyle={{ fontWeight: 'bold' }}>
              {project.estimated_hours || '-'}&nbsp;
              {project.cost_per_hour ? `(${project.cost_per_hour}€ / hour)` : ''}
            </Descriptions.Item>
            <Descriptions.Item label="">{renderDaysLeftProgressBar(project)}</Descriptions.Item>
            <Descriptions.Item label="Delivery Date" contentStyle={{ fontWeight: 'bold' }}>
              {project.delivered_at ? new Date(project.delivered_at).toLocaleDateString() : '-'}
            </Descriptions.Item>
            <Descriptions.Item label="Hours Spent" contentStyle={{ fontWeight: 'bold' }}>
              {project.hours} hours
            </Descriptions.Item>
            <Descriptions.Item label="">{renderHoursLeftProgressBar(project)}</Descriptions.Item>
          </Descriptions>
        </div>
        <div className="extra">
          <div
            style={{
              display: 'flex',
              width: 'max-content',
              justifyContent: 'flex-end'
            }}
          >
            <Statistic
              title="Project Offer"
              suffix="€"
              value={
                project.estimated_hours && project.cost_per_hour
                  ? (project.estimated_hours * project.cost_per_hour).toFixed(2)
                  : 0
              }
              style={{
                marginRight: 32
              }}
            />
            <Statistic
              title="Burned Amount"
              value={project.cost_per_hour ? (project.hours * project.cost_per_hour).toFixed(2) : 0}
              suffix="€"
              style={{
                marginRight: 32
              }}
            />
            <Statistic
              title="Amount Diff"
              value={amountDiff.toFixed(2) || 0}
              valueStyle={{ color: amountDiffColor(amountDiff) }}
              prefix={renderAmountDiffIcon(amountDiff)}
              suffix="€"
            />
          </div>
        </div>
      </div>
    </PageHeader>
  );
};

export default ProjectHeader;
