import { Form, Select } from 'antd';
import { IAssignee } from '../../services/tasks';

interface ITaskAssigneesProps {
  isLoading: boolean;
  assignees: IAssignee[];
}

const TaskAssignees = ({ isLoading, assignees }: ITaskAssigneesProps) => {
  return (
    <Form.Item name="assignee" label="Assignee">
      <Select
        loading={isLoading}
        showSearch
        allowClear
        placeholder="Choose assignee"
        options={assignees}
        filterOption={(input, option: any) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
    </Form.Item>
  );
};

export default TaskAssignees;
