import { message } from 'antd';

export const handleErrorResponse = (e: any) => {
  if (!e?.response) return;

  const statusCode = e?.response?.status;

  if (statusCode === 422) {
    return message.error(e.response.data.errors[Object.keys(e.response.data.errors)[0]][0]);
  }

  if (statusCode === 401) {
    return message.error('Session expired. Please sign in again.');
  }

  if (statusCode === 403) {
    return message.error('You are not authorized to perform this action.');
  }

  if (statusCode === 429) {
    return message.error(
      'You have made too many requests, please wait up to a minute before the next one.'
    );
  }

  return message.error(
    e?.response?.data?.error || e?.response?.data?.errors?.error || 'Something went wrong!'
  );
};
