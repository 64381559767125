import { EditOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, InputNumber, message, Row } from 'antd';
import { useState } from 'react';
import { updateProject } from '../../services/projects';
import { handleErrorResponse } from '../../utilities/errors';
import { IProject } from '../../services/projects';
import moment from 'moment';

interface IProjectProps {
  project: IProject;
}

const ProjectBasicDetails = ({ project }: IProjectProps) => {
  const [form] = Form.useForm();
  const [isUpdating, setIsUpdating] = useState(false);

  const onUpdate = async (values: any) => {
    setIsUpdating(true);

    try {
      const { title, description, costPerHour, estimatedHours, timeline } = values;
      const startedAt = timeline.length > 1 ? timeline[0]?.format('YYYY-MM-DD') : null;
      const deliveredAt = timeline.length === 2 ? timeline[1]?.format('YYYY-MM-DD') : null;
      await updateProject(project?.id, {
        title: title,
        description: description,
        cost_per_hour: costPerHour,
        estimated_hours: estimatedHours,
        started_at: startedAt,
        delivered_at: deliveredAt
      });
      message.success('Project updated successfully.');
    } catch (error) {
      handleErrorResponse(error);
    }
    setIsUpdating(false);
  };

  return (
    <Form
      onFinishFailed={(e) => console.log(e)}
      initialValues={{
        title: project?.title,
        description: project?.description,
        costPerHour: project?.cost_per_hour,
        estimatedHours: project?.estimated_hours,
        timeline: [
          project?.started_at !== null ? moment(project?.started_at, 'YYYY-MM-DD') : undefined,
          project?.delivered_at !== null ? moment(project?.delivered_at, 'YYYY-MM-DD') : undefined
        ]
      }}
      form={form}
      onFinish={onUpdate}
      layout="vertical"
    >
      <Form.Item
        name="title"
        label="Title"
        required
        rules={[{ required: true, message: 'Please enter a valid title!' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input.TextArea />
      </Form.Item>

      <Row gutter={24} justify="space-between">
        <Col span={12}>
          <Form.Item name="costPerHour" label="Cost per hour">
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="estimatedHours" label="Estimated hours">
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="timeline" label="Project timeline">
        <DatePicker.RangePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
      </Form.Item>

      <Form.Item style={{ textAlign: 'right', marginBottom: 0 }}>
        <Button icon={<EditOutlined />} type="primary" loading={isUpdating} htmlType="submit">
          Update project
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProjectBasicDetails;
