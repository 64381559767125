import { LoginOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, message, Row } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthLayout from '../layouts/AuthLayout';
import { login } from '../services/auth';
import { UserContext } from '../store';

const Login: FC = () => {
  const navigate = useNavigate();
  const { isLoggedIn, setData } = useContext(UserContext);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = async (values: any) => {
    setIsSubmitting(true);
    const { email, password } = values;
    try {
      const { data: response } = await login(email, password);
      setData({
        token: response?.data?.access_token,
        isLoggedIn: true,
        user: { ...response?.data?.user }
      });
      navigate('/dashboard');
    } catch (error) {
      message.error('Incorrect credentials. Please try again.');
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard');
    }
  }, [navigate, isLoggedIn]);

  return (
    <AuthLayout>
      <Card title="Login to continue">
        <Form name="basic" labelCol={{ span: 6 }} onFinish={submit} autoComplete="off">
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: 'Please enter a valid email address!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please enter a valid password!' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            style={{ marginBottom: 0 }}
            wrapperCol={{
              xs: 24,
              sm: { offset: 6, span: 18 },
              md: { offset: 6, span: 18 },
              lg: { offset: 6, span: 18 }
            }}
          >
            <Row style={{ marginBottom: 0 }} justify="space-between">
              <Button
                icon={<UserAddOutlined />}
                onClick={() => navigate('/register')}
                type="default"
                htmlType="button"
              >
                Create an account
              </Button>
              <Button
                icon={<LoginOutlined />}
                type="primary"
                htmlType="submit"
                loading={isSubmitting}
              >
                Login
              </Button>
            </Row>
          </Form.Item>
        </Form>
      </Card>
    </AuthLayout>
  );
};

export default Login;
